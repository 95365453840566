import { useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Navbar/Sidebar";
import Footer from "../components/Footer";
import GalleryContainer from "../components/Gallery/GalleryContainer";

const Gallery = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(null);

  return(
    <>
      <Header setIsSidebarOpened={setIsSidebarOpened}/>
      <div className="gallery_page">
        <Sidebar
          isSidebarOpened={isSidebarOpened}
          setIsSidebarOpened={setIsSidebarOpened}
        />
        <GalleryContainer />
        <Footer />
      </div>
    </>
  )
}
export default Gallery