import React, { useEffect, useState } from "react";
import MobileNavbar from "./Navbar/MobileNavbar";
import Navbar from "./Navbar/Navbar";
import SearchSite from "./SearchSite";

const HomeHeader = ({ offset, setIsSidebarOpened }) => {
  const [openedTab, setOpenedTab] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > (offset || 0)) {
      document.querySelector(".home-header-area").classList.add("home-sticky");
    } else {
      document.querySelector(".home-header-area").classList.remove("home-sticky");
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <>
      <div className="home-header-area col-12" onMouseLeave={() => setOpenedTab(false)}>
        <div>
          <SearchSite />
          <Navbar
            openedTab={openedTab}
            setOpenedTab={setOpenedTab}
            isHomeNavbar={true}
          />
          <MobileNavbar setIsSidebarOpened={setIsSidebarOpened} />
          <div className="line rotate_180 d-none d-md-block"></div>
        </div>
      </div>
    </>
  );
};

export default HomeHeader;
