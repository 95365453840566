import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Navbar/Sidebar";
import ProductItem from "../components/Products/ProductItem";
import Scrolltop from "../components/Scrolltop";
import WhiteGrayBackground from "../containers/BackgroundContainer/WhiteGrayBackground";
import { api } from "../services"

const GameMixes = () => {
  const [gameMixes, setGameMixes] = useState([]);
  const [searchedItems, setSearchedItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 999;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = searchedItems.slice(itemOffset, endOffset);
  const [isSidebarOpened, setIsSidebarOpened] = useState(null);
  const [platforms, setPlatforms] = useState(null);

  const handleChange = (event) => {
    setItemOffset(0);
    const value = event.target.value.toLocaleLowerCase();
    const newArray = gameMixes.filter((item) =>
      item.name.toLocaleLowerCase().includes(value)
    );
    setSearchedItems(newArray);
  };

  const handleSelect = (selectedOption) => {
    setItemOffset(0);
    const value = selectedOption.value.toLocaleLowerCase();

    if (value === "sve") {
      setSearchedItems(gameMixes);
    } else {
      const newArray = gameMixes.filter(
        (item) => item.platforms[0].name.toLocaleLowerCase() === value
      );
      setSearchedItems(newArray);
    }
  };

  useEffect(() => {
    api.gameMixes.getGameMixes().then(res => {
      if (!res) return;
      setGameMixes(res);
      setSearchedItems(res);
    })

    api.platforms.getPlatforms().then(res => {
      if(!res) return;
      const platformsFormatted = [{ value: "Platforma", label: "Platforma", disabled: true }, { value: "Sve", label: "Sve" }]
      res.map((item, key) => (
        platformsFormatted.push(
          {
            value: item.name,
            label: item.name
          }
        )
      ))
      setPlatforms(platformsFormatted)
    })
  }, [])

  return (
    <>
      <Header setIsSidebarOpened={setIsSidebarOpened} />
      <div className="game_mix_page">
        <img src="/images/header_images/mix_igara.webp" className="w-100 d-none d-md-block" alt=""></img>
        <img src="/images/header_images/mix_igara_mobile.webp" className="w-100 d-md-none" alt=""></img>
        <WhiteGrayBackground title={"GAME MIXES"} handleChange={handleChange} handleSelect={handleSelect} options={platforms} showFilters={true} resultNumber={currentItems.length}>
          <div className="games row pe-0 m-0 p-0">
            {currentItems.map((item, key) => {
              return (
                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6"
                  key={key}
                >
                  <ProductItem data={item} route="/mix-igara-detalji/" type="game-mix" />
                </div>
              );
            })}
          </div>
        </WhiteGrayBackground>
        <Sidebar
          isSidebarOpened={isSidebarOpened}
          setIsSidebarOpened={setIsSidebarOpened}
        />
        <Footer />
        <Scrolltop />
      </div>
    </>
  );
};

export default GameMixes;
