import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { api } from "../../services"

const Sidebar = ({ isSidebarOpened, setIsSidebarOpened }) => {

  const ref = useRef();
  const [cabinets, setCabinets] = useState([]);

  const closeSidebar = () => {
    setIsSidebarOpened(false);
    const sidebarBox = ref.current.querySelector('.sidebar_box');
    if (sidebarBox.classList.contains('w-100')) {
      sidebarBox.classList.remove('w-100');
      sidebarBox.classList.add('w-0');
    }
  }
  const sidebarRef = useRef()

  const handleSidebar = () => {
    setIsSidebarOpened(!isSidebarOpened);
  }

  const fetchCabinets = () => {
    api.cabinets.getCabinets().then(res => {
      if (!res) return;
      setCabinets(res)
    })
  }

  useEffect(() => {
    if(!isSidebarOpened || cabinets.length) return;
    fetchCabinets();
  }, [isSidebarOpened])

  return (
    <>
      <div className="sidebar-container d-none d-md-block" ref={sidebarRef}>
        <div className="icons">
          <Link to="/kontakt" className="text-decoration-none">
            <div className="card envelope mb-3 d-flex flex-row align-items-center" role="button">
              <i className=" fa fa-sharp fa-solid fa-envelope me-2"></i>
              <p className="m-0">KONTAKT</p>
            </div>
          </Link>
          <div className="card star d-flex flex-row align-items-center" role="button" onClick={handleSidebar}>
            <i className="fa fa-sharp fa-solid fa-star me-2"></i>
            <p className="m-0">AUTOMATI</p>
          </div>
        </div>
      </div>
      <div ref={ref}>
        <div className={`sidebar_box ${isSidebarOpened ? "sidebarBox_opened" : ""}`}>
          <div className="close_btn text-end pe-2" role="button" onClick={closeSidebar}>&times;</div>
          <div className="sidebar_content text-start">
            <h4 className="sidebar_title">Automati</h4>
            {cabinets.map((item, key) => {
              return (
                <Link to={`/automati-detalji/${item.id}`} key={key}>
                  <div role="button" onClick={closeSidebar}>
                    <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.cover_image_small.src}`} className="w-100" alt=""></img>
                    <div className="sidebar_content_card d-flex justify-content-between align-items-center">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="pt-2 sidebar_card_title">{item.name}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
        <div className={`overlay ${isSidebarOpened ? "fullOverlay" : ""}`} onClick={closeSidebar}></div>
      </div>
    </>
  )
}

export default Sidebar;