import React, { useState } from "react";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";
import Sidebar from "../components/Navbar/Sidebar";
import AboutNovomatic from "../components/About/AboutNovomatic";

const About = () => {
  const [isSidebarOpened, setIsSidebarOpened] = useState(null);

  return (
    <>
      <Header setIsSidebarOpened={setIsSidebarOpened}/>
      <div className="about_page">
        <AboutNovomatic />
        <Sidebar 
            isSidebarOpened={isSidebarOpened}
            setIsSidebarOpened={setIsSidebarOpened}
          />
        <Footer />
        <Scrolltop />
      </div>
    </>
  );
};

export default About;
