import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';

const NovomaticMap = withScriptjs(withGoogleMap((props) => {

  return(
      <GoogleMap
    //   defaultOptions={{
    //     styles: styles
    //   }}
      defaultZoom={15}
      defaultCenter={{lat: 44.807113, lng: 20.383407}}
    >
      <Marker 
      position={{lat: 44.807113, lng: 20.383407}} 
      icon={{
        // path: google.maps.SymbolPath.CIRCLE,
        url: (require('../../assets/map_marker.png')),
        fillColor: '#EB00FF',
        scale: 7,
      }}
    >
    </Marker>
    </GoogleMap>
  )
}
))
export default NovomaticMap