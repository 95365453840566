import { createCabinetsApi } from "./cabinets";
import { createCategoriesApi } from "./categories";
import {fetchApi} from "./fetch-api";
import {createGalleryApi} from "./gallery";
import { createGameMixesApi } from "./gameMixes";
import { createNewsApi } from "./news";
import { createPlatformsApi } from "./platforms";

function createApi(api) {
  return {
    gallery: createGalleryApi(api),
    categories: createCategoriesApi(api),
    news: createNewsApi(api),
    cabinets: createCabinetsApi(api),
    gameMixes: createGameMixesApi(api),
    platforms: createPlatformsApi(api)
  };
}

export const api = createApi(fetchApi);