import React from "react";
import { Link } from "react-router-dom";

const Login = ({data}) => {

  return(
    <div className="user_login text-start pt-4 px-4 col-12 col-md-5 col-xl-12 ms-auto">
      <div className="d-flex">
        <h4 className="px-3">{data.login.title}</h4>
        <i className="fa fa-lock blue"></i>
      </div>
      <div className="p-2">
        <div className="py-2">
          <input className="col-12"></input>
        </div>
        <div className="py-2">
          <input className="col-12"></input>
        </div>
        <div className="d-flex justify-content-between">
          <Link to="https://www.novomatic.com/en/login" target="blank" className="btn_load_more hover_btn_load_more my-4 px-3 text-uppercase" href="/login">{data.login.button}</Link>
          <ul className="ps-3 pt-3">
            {data.login.links.map((link, key) => (
              <li key={key}>
                <a href={link.href} className="blue create-account-link text-decoration-underline text-link" target="blank">{link.title}</a>
              </li>                          
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Login;