import React from "react";
import { useLocation } from "react-router";

const GrayDotsBackground = ({ title, children, subtitle }) => {
  const location = useLocation();

  return (
    <div className="gray_dots_background_container">
      <div className="gray_dots_background_section">
        {title && (
          <div>
            <div>
              <h2 className={`shadowtitle ${location.pathname === "/podrska" ? "text-black" : "text-white"}`}>{title}</h2>
              <h6>{subtitle}</h6>
            </div>
          </div>
        )}
        <div className="row justify-content-between m-0">{children}</div>
      </div>
    </div>
  );
};

export default GrayDotsBackground;
