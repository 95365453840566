import React, { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Support from "./pages/Support";
import Career from "./pages/Career";
import "./assets/style.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Cabinets from "./pages/Cabinets";
import Games from "./pages/Games";
import CabinetDetails from "./pages/CabinetDetails";
import NewsDetails from "./pages/NewsDetails";
import News from "./pages/News";
import ScrollToTop from "./scroll-to-top";
import GameMixes from "./pages/GameMixes";
import GameMixDetails from "./pages/GameMixDetails";
import Gallery from "./pages/Gallery";
import { useRive } from "@rive-app/react-canvas";
import { NovoProvider } from "./context/novoinvesment";

const animations = {
  desktop: "/animations/Novomatic-Splash.riv",
  mobile: "/animations/Novomatic-splash-mob.riv"
}

function App() {
  const [showAnimation, setShowAnimation] = useState(false);
  const getDeviceAnimation = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i) ? animations.mobile : animations.desktop;

  const { rive, RiveComponent } = useRive({
    src: getDeviceAnimation,
    autoplay: false,
    onStop: () => {
      document.querySelector('.landing-animation').classList.add('opacity');
      setTimeout(() => {
        document.querySelector(".app-content").classList.remove("full-screen");
      }, 1000);
      setTimeout(() => {
        setShowAnimation(false);
        sessionStorage.setItem("visited", "yes");
      }, 3000)
    }
  });

  useEffect(() => {
    if (sessionStorage.getItem("visited")) {
      document.querySelector(".app-content").style.opacity = 1;
      document.querySelector(".app-content").classList.remove("full-screen");
      return;
    }
    setShowAnimation(true);

    if (rive) {
      rive.play()
      setTimeout(() => {
        document.querySelector(".app-content").classList.add("opacity");
      }, 6500);
    }
  }, [rive]);


  useEffect(() => {

  }, [])


  return (
    <>
      {showAnimation && (
        <>
          <div className="landing-animation">
            <RiveComponent />
          </div>
        </>
      )}
      <div className="app-content full-screen">
        <ScrollToTop>
          <NovoProvider>
            <Routes>
              <Route
                path="/" element={<Home />}
              />
              <Route
                path="/o-nama" element={<About />}
              />
              <Route
                path="/kontakt" element={<Contact />}
              />
              <Route
                path="/podrska" element={<Support />}
              />
              <Route
                path="/karijere" element={<Career />}
              />
              <Route
                path="/mix-igara" element={<GameMixes />}
              />
              <Route
                path="/automati" element={<Cabinets />}
              />
              <Route
                path="/mix-igara-detalji/:id" element={<GameMixDetails />}
              />
              <Route
                path="/igre" element={<Games />}
              />
              <Route
                path="/novosti" element={<News />}
              />
              <Route
                path="/novosti-detalji/:id" element={<NewsDetails />}
              />
              <Route
                path="/automati-detalji/:id" element={<CabinetDetails />}
              />
              <Route
                path="/galerija" element={<Gallery />}
              />
            </Routes>
          </NovoProvider>
        </ScrollToTop>
      </div>
    </>
  );
}

export default App;
