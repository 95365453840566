import CardItem from "../../components/Card/CardItem";
import games from "../../data/GamesData.json"

const GamesContainer = () => {
  return(
    <div className="game-container">
      <div className="gray_dots_background_section">
      <div className="pt-3 row justify-content-center w-100 m-0">
        <h2 className="shadowtitle px-2">IGRE</h2>
      </div>
        <div className="row justify-content-between m-0">
          {games.map((item, key) => {
            return (
              <div className="row col-lg-4 col-md-6 col-sm-6 col-12 p-0 m-0" key={key}>
                <CardItem data={item} route={item.route}/>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  )
}
export default GamesContainer