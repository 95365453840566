import React, { createContext, useReducer } from 'react';

export const NovoContextType = {
  SET_CABINETS: 'SET_CABINETS'
}

const InitialState = {
  cabinets: null
}

export const NovomaticContext = createContext();

const NovoReducer = (state, action) => {
  switch (action.type) {
    case NovoContextType.SET_CABINETS: {
      return { ...state, cabinets: action.data }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export const NovoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NovoReducer, InitialState)
  const value = { state, dispatch }
  return <NovomaticContext.Provider value={value}>{children}</NovomaticContext.Provider>
}
