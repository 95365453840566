import React, { useState } from "react";
import ProductCard from "./ProductCard";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useRef } from "react";
import Slider from "../Slider/Slider";
import ImageGallery from "react-image-gallery"
import { api } from "../../services";
import games from "../../data/GamesData.json"
import { formatDate } from "../../utils/validation";

export const tabs = [
  {
    id: 1,
    img: "automati",
    title: "AUTOMATI"
  },
  {
    id: 2,
    img: "igre",
    title: "IGRE"
  },
  {
    id: 3,
    img: "novosti",
    title: "NOVOSTI"
  },
  {
    id: 4,
    img: "galerija",
    title: "GALERIJA"
  }
];

const newsResponsive = [
  {
    itemsToShow: 1,
    maxWidth: 600,
  },
  {
    itemsToShow: 1,
    minWidth: 600,
  },
  {
    itemsToShow: 2,
    minWidth: 1200,
  },
  {
    itemsToShow: 2,
    minWidth: 1500,
  }
]

const galleryResponsive = [
  {
    itemsToShow: 1,
    maxWidth: 600,
  },
  {
    itemsToShow: 1,
    minWidth: 600,
  },
  {
    itemsToShow: 2,
    minWidth: 1200,
  },
  {
    itemsToShow: 3,
    minWidth: 1500,
  }
]


const ProductsContainer = () => {
  const ref = useRef();
  const [tab, setTab] = useState(tabs[0].id);
  const [tabChanged, setTabChanged] = useState(false);
  const [startIndex, setStartIndex] = useState();
  const [isGalleryOpened, setIsGalleryOpened] = useState(false);
  const [cabinets, setCabinets] = useState(null);
  const [news, setNews] = useState(null);
  const [gallery, setGallery] = useState(null);
  const navigate = useNavigate();

  const selectGameType = (id) => {
    setTab(id)
  }

  const handleScroll = () => {
    if (window.scrollY > 100) ref.current.querySelector('.mobile-cabinets2').classList.add('show-in');
    if (window.scrollY > 400) cabinetsAnimation();
  };

  const cabinetsAnimation = () => {
    let counter = 0;
    const cabinetsToAnimate = ref.current.querySelectorAll('.cabinets > div');
    const reversedCabinets = [];
    for (let i = cabinetsToAnimate.length - 1; i >= 0; i--) {
      reversedCabinets.push(cabinetsToAnimate[i]);
    }

    let interval = setInterval(() => {
      const singleCabinetToAnimate = reversedCabinets[counter];
      singleCabinetToAnimate && singleCabinetToAnimate.classList.add('fade-in')
      counter++

      if (counter === cabinetsToAnimate.length) {
        clearInterval(interval);
      }
    }, 200);
  }

  const handleLink = (event) => {
    event.preventDefault();
  }

  const openGallery = (event) => {
    document.querySelector('.image-gallery').classList.add('showGallery');
    setStartIndex(Number(event.target.id))
    setIsGalleryOpened(true);
  }

  const closeGallery = () => {
    document.querySelector('.image-gallery').classList.remove('showGallery');
    setIsGalleryOpened(false);
  }

  const handleClick = (event, item) => {
    if (item.route) {
      if (item.route.startsWith('http')) {
        window.open(item.route, '_blank');
      }
      else {
        navigate(item.route)
      }
    }
  }

  const fetchCabinets = () => {
    api.cabinets.getCabinets().then(res => {
      if (!res) return;
      setCabinets(res);
    })
  }

  const fetchNews = () => {
    api.news.getAllNews().then(res => {
      if (!res) return;
      setNews(res);
    })
  }

  const fetchGallery = () => {
    api.gallery.getActiveImages().then(res => {
      if (!res) return;
      setGallery(res);
    })
  }

  useEffect(() => {
    if (tabChanged) return;
    if (tab === 1) {
      document.addEventListener("scroll", handleScroll);
      handleScroll();
      ref.current.querySelector('.mobile-cabinets1').classList.add('show-in');
    } else {
      setTabChanged(true);
      document.removeEventListener("scroll", handleScroll);
    }
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [tab])

  useEffect(() => {
    const gallery = document.querySelector('.image-gallery-slides');
    if (!gallery) return;
    //creating close button for gallery
    const close = document.createElement('div');
    const line1 = document.createElement('div');
    line1.classList.add('line1');
    const line2 = document.createElement('div');
    line2.classList.add('line2');
    close.classList.add('gallery-close-button');

    close.appendChild(line1);
    close.appendChild(line2);

    close.addEventListener('click', closeGallery);
    gallery.appendChild(close);
  })

  useEffect(() => {
    fetchCabinets();
    fetchNews();
    fetchGallery();

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [])

  return (
    <>
      <div className="line"></div>
      <div className="product_container pb-0">
        <div className="product_section">
          <div className="row justify-content-around justify-content-md-center ms-0 w-100 g-0" role="button">
            {tabs.filter(item => !item.href).map((item, key) => {
              return (
                <div className="tab col-2 d-flex px-0 align-items-end justify-content-center" onClick={() => selectGameType(item.id)} key={key}>
                  <Link to={item.href} className="text-decoration-none" onClick={handleLink}>
                    <ProductCard active={item.id === tab} data={item}></ProductCard>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="mb-md-4">
        <div className="show-products-container align-items-center justify-content-center row m-0 p-0 py-2 pt-md-3" ref={ref}>
          {tab === 1 && (
            <>
              <div className="cabinets row align-items-center justify-content-center d-none d-sm-flex">
                {(cabinets || []).slice(0, 4).map(item => (
                  <div className={`col-6 col-md-3 text-center ${tabChanged ? 'visible' : ''}`} id={item.id} key={item.id}>
                    <Link to={`/automati-detalji/${item.id}`}>
                      <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.cabinet_image.src}`} className=" home-cabinet-image" alt=""></img>
                    </Link>
                    <div className="title text-black pt-3">{item.name}</div>
                  </div>
                ))}
                <Link to="/automati" className="btn_load_more hover_btn_load_more btn_large w-auto px-4 mt-5">UČITAJ VIŠE</Link>
              </div>
              <div className={`mobile-cabinets1 row justify-content-center align-items-end d-flex d-sm-none ${tabChanged ? 'show' : ''} pt-4`}>
                {(cabinets || []).slice(0, 2).map(item => (
                  <div className={`col-6 col-md-3 text-center row g-0`} style={{ height: "max-content" }} key={item.id}>
                    <Link to={`/automati-detalji/${item.id}`}>
                      <div>
                        <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.cabinet_image.src}`} alt="" className="w-100"></img>
                      </div>
                    </Link>
                    <div className="title" style={{ padding: "0", height: "max-content" }}>{item.name}</div>
                  </div>
                ))}
              </div>
              <div className={`mobile-cabinets2 row justify-content-center align-items-end d-flex d-sm-none ${tabChanged ? 'show' : ''}`}>
                {(cabinets || []).slice(2, 4).map(item => (
                  <div className={`col-6 col-md-3 text-center row g-0`} style={{ height: "max-content" }} key={item.id}>
                    <Link to={`/automati-detalji/${item.id}`}>
                      <div>
                        <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.cabinet_image.src}`} alt="" className="w-100"></img>
                      </div>
                    </Link>
                    <div className="title" style={{ padding: "0", height: "max-content" }}>{item.name}</div>
                  </div>
                ))}
                <Link to="/automati" className="btn_load_more hover_btn_load_more w-auto px-4 mt-md-5 mt-0 mb-md-0 mb-3">UČITAJ VIŠE</Link>
              </div>
            </>
          )}
          {tab === 2 && (
            <div className="games row justify-content-center px-5 pt-5 pb-4">
              {games.map((item, key) => (
                <div className="col-4 about_item_container mb-5 mx-auto" style={{ width: "18rem" }} key={key}>
                  <div className="text-decoration-none" role="button" onClick={(event) => handleClick(event, item)}>
                    <div className="row img-container">
                      <img src={item.image} className="p-0" alt=""></img>
                      <div className="title text-center row g-0 justify-content-center align-items-center">{item.title}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {tab === 3 && (
            <div className="news_tab g-0 px-2 pt-md-2 pt-4">
              <Slider items={3} responsiveProps={newsResponsive}>
                {news.map((item, key) => {
                  return (
                    <div
                      style={{
                        border: "15px solid transparent",
                        textAlign: "center",
                        boxSizing: "border-box"
                      }}
                      key={key}
                    >
                      <div className="col-6 new_tab_content mx-auto slider" key={key}>
                        <div className="single-news p-0 mb-2">
                          <Link to={`/novosti-detalji/${item.id}`}>
                            {!!item.is_new && (
                              <img src="/images/icons/new.svg" alt="" className="new_tag"></img>
                            )}
                            {!!item.is_coming_soon && (
                              <img src="/images/icons/coming-soon.webp" alt="" className="new_tag p-1"></img>
                            )}
                            <img className="w-100" src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.cover_image_small.src}`} alt=""></img>
                            <div className="content text-start px-2 px-md-3 w-100 row align-items-end g-0">
                              <div className="description">
                                <div className="tag mb-3">{item.tag}</div>
                                <div className="firstNews-text pb-3">{item.title.toUpperCase()}</div>
                                <div className="firstNews-date pb-2">{formatDate(item.date)}</div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          )}
          {tab === 4 && (
            <div className="gallery_tab g-0 px-2 pt-md-2 pt-4">
              <Slider items={3} responsiveProps={galleryResponsive}>
                {gallery.map((item, key) => {
                  return (
                    <div
                      style={{
                        border: "15px solid transparent",
                        textAlign: "center",
                        boxSizing: "border-box"
                      }}
                      key={key}
                    >
                      <div className="col-6 mx-auto slider" style={{ width: "18rem" }} key={key}>
                        <div className="p-0 mb-2">
                          <img className="w-100" id={key} src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.thumbnail}`} alt="" role="button" onClick={openGallery}></img>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          )}
        </div>
        <div className='gallery_container'>
          <ImageGallery
            items={(gallery || []).map((item, key) => ({ ...item, original: `${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.original}`, thumbnail: `${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.thumbnail}` }))}
            startIndex={startIndex}
            showPlayButton={false}
            showFullscreenButton={false}
          />
        </div>
        <div onClick={closeGallery} className={`${isGalleryOpened ? "fullOverlay" : ""}`}></div>
      </div>
    </>
  )
}

export default ProductsContainer;
