import React, { useState, useRef } from "react";
import { useLocation } from "react-router";
import { validateForm } from "../../utils/validation";

const ContactForm = ({data}) => {
  const location = useLocation();
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    message: "",
    formType: location.pathname === "/kontakt" ? "contact" : "support",
    to: location.pathname === "/kontakt" ? "office@novoinvestment.com" : "service@novoinvestment.com,jpupovac@novomatic.com,mtrailovic@novomatic.com"
  });
  const [formSubmited, setFormSubmited] = useState(false);

  const sendContactMessage = (event) => {
    if (!validateForm(formRef.current)) return;
    const _formData = new FormData();
    for(var i in formData) {
      _formData.append(i , formData[i]);
    }
    fetch('https://novoinvestment4.cubenet.rs/mail.php', {method: 'POST', body: _formData}).then(res => res.text());
    setFormSubmited(true);
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return(
    <>
      <div className="mx-0">
        <div className="col-12 pr-30">
          {formSubmited === false && (
            <form ref={formRef} className="contact-form has-validation">
            <div className="row">
              <div className="p-0 p-sm-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 text-start">
                <label>{data.name}*</label>
                <input required onChange={changeHandler} className="form-control required" type="text" name="name" />
              </div>
              <div className="p-0 p-sm-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 text-start">
                <label>E-mail adresa*</label>
                <input required onChange={changeHandler} className="form-control" type="email" name="email" />
              </div>
              {location.pathname === "/podrska" && (
                <div className="p-0 p-sm-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 text-start">
                  <label className="text-allign-left">{data.serial}*</label>
                  <input required onChange={changeHandler} className="form-control" type="text" name="serialNumber" />
                </div>
              )}
              <div className={`p-0 p-sm-4 ${location.pathname === "/kontakt" ? "col-xl-12 col-lg-12 col-md-12" : "col-xl-6 col-lg-6 col-md-6"} col-sm-12 text-start`}>
                <label>{data.company}*</label>
                <input
                  required 
                  onChange={changeHandler}
                  className="form-control"
                  type="text"
                  name="company"
                />
              </div>
              <div className="col-12 px-0">
                <div className="p-sm-0 p-md-4 col-xl-12 text-start">
                  <label>{data.message}</label>
                  <textarea
                    required
                    onChange={changeHandler}
                    className="form-control"
                    name="message"
                    cols="30"
                    rows="8"
                  ></textarea>
                </div>
                <div className="text-center pt-4">
                  <button
                    type="button"
                    onClick={sendContactMessage}
                    className="btn_load_more hover_btn_load_more px-5"
                  >
                    Pošalji
                  </button>
                </div>
                <p className="form-message float-left mt-15"></p>
              </div>
            </div>
          </form>
          )}
          {formSubmited && (
            <div className="w-100 my-5 text-black" >Hvala vam što ste nas kontaktirali. Odgovor ćete dobiti u što kraćem roku!</div>
          )}
        </div>
      </div>
    </>
  )
}

export default ContactForm;