import React from "react";
import { useNavigate } from "react-router";

const CardItem = ({data, route}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (data.route) {
      if(data.route.startsWith('http')){
        window.open(data.route, '_blank');
      }
      else{
        data.route === "/novosti-detalji/" ? navigate(route + data.id) : navigate(data.route);
      }
    }
  }

  return(
    <div className="col-6 about_item_container about-border mb-4 mx-auto" style={{width: "20rem"}}>
      {data.date && (
          <div className="news_date ms-4">
              <p>NEWS</p>
              <div>{data.date}</div>
          </div>
      )}
      <div className="row">
        <img src={data.image} className="rounded-top p-0" alt=""></img>
      </div>
      <div className={`row justify-content-between card-body py-4 p-md-3 rounded-bottom card_item ${data.date ? 'grayBackground' : 'bg-white'}`}>
        <div className="text-center">
          <h5 className="card-title py-2 my-2">{data.title}</h5>
          <p className="card-text pb-4">{data.content}</p>
        </div>
        <div className="row col-12 align-items-end justify-content-center p-0 m-0">
          <div className="col-8 py-2 text-center row justify-content-center mt-3">
            <div className="btn_load_more hover_btn_load_more mt-3" onClick={handleClick}>Više informacija</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardItem;