import { useRive } from "@rive-app/react-canvas";
import { useEffect } from "react";

const Animation = ({riveSrc, onStop, offset, inView}) => {
  const { rive, RiveComponent } = useRive({
    src: riveSrc,
    autoplay: false,
    onStop: onStop
  });

  const handleScroll = () => {
      if (!rive) return;
      if(window.scrollY > offset || inView) rive.play();
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  })

  return(
    <div>
      <RiveComponent/>
    </div>
  )
}
export default Animation