export const createNewsApi = (fetchApi) => {

  // function getAllNews() {
  //   return fetchApi(`/p/news`, 'GET');
  // }

  // function getNewsById(id) {
  //   return fetchApi(`/p/news/${id}`, 'GET');
  // }

  function getAllNews() {
    return fetchApi(`/news`, 'GET');
  }

  function getNewsById(id) {
    return fetchApi(`/news/${id}`, 'GET');
  }

  return {
    getAllNews,
    getNewsById
  };
};