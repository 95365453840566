import WhiteGrayBackground from "../../containers/BackgroundContainer/WhiteGrayBackground"
import img1 from "../../assets/about1.webp"
import img2 from "../../assets/about5.webp";
import img3 from "../../assets/about6.webp";
import img5 from "../../assets/about8.webp";
import img6 from "../../assets/about9.webp";
import img7 from "../../assets/about10.webp";

const AboutNovomatic = () => {
  return(
    <>
      <img src="/images/header_images/o_nama.webp" className="w-100 d-none d-md-block" alt=""></img>
      <img src="/images/header_images/o_nama_mobile.webp" className="w-100 d-md-none" alt=""></img>
      <WhiteGrayBackground title={"NOVOMATIC"}>
          <div className="container about">
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 pe-md-4">
                <div className="about_tab bg-white radius-4 blue_text">
                  <div className="about_tab_header text-uppercase text-center">istorijat</div>
                  <div className="p-4 text-start">
                    Klasa, tradicija, poverenje - tako se u najkraćem mogu opisati sastojci iz kojih je nastao Novo Investment pre više od 15 godina.<br/><br/>
                    Novo Investment d.o.o je deo NOVOMATIC grupacije, jednog od svetskih lidera u proizvodnji gaming opreme i tehnologije, a osnovan je 2007. godine kao deo strategije širenja na balkansko tržište.<br/>
                    NOVOMATIC AG postoji više od četrdeset godina – nastao je 1980. godine u Austriji kao ideja profesora Johana F. Grafa. Tada mala kompanija, vremenom je proširila poslovanje na ceo svet i sada ima svoja predstavništva u preko 50 zemalja, izvozi elektronsku gaming opremu i softverska rešenja u skoro 100 država i zapošljava preko 23.000 radnika širom sveta.<br/>
                    Novo Investment d.o.o. je zvanični uvoznik NOVOMATIC proizvoda u Srbiji, kao i jedini ovlašćeni predstavnik za prodaju, rentiranje i servisiranje celokupne opreme iz proizvodnog programa kompanije NOVOMATIC.<br/><br/>
                  </div>
                </div>
                <div className="pb-1 pb-xl-0 m-auto">
                  <img src={img2} alt="" className="radius-4 my-4 my-md-5"></img>
                </div>
                <div className="about_tab bg-white radius-4 blue_text">
                  <div className="about_tab_header text-uppercase text-center">DRUŠTVENA ODGOVORNOST</div>
                  <div className="p-4 text-start">
                    U skladu sa politikom osnivača, držimo se društene odgovornosti kao postulata u načinu funkionisanja kompanije. Kroz donacije i druge vrste pomoći društvenoj zajednici, prisutni smo u našoj zemlji i regionu. Pomogli smo značajnim sredstvima u različitim situacijama, od prirodnih nepogoda i poplava, preko raznih izazova sa kojima se suočavaju društvo i pojedinci, do pandemije proteklih godina. 
                    <br/><br/>
                    NOVOMATIC Srbija podržava Asocijaciju Gejming Operatera Srbije – AGOS, koja između ostalog ima za misiju širenje svesti i edukaciju o odgovornom pristupanju igrama na sreću i prevenciju zavisnosti. U skladu sa time, jedan od prioriteta udruženja je odgovorno pristupanje igrama na sreću. U oktobru 2022. pokrenute su tribine “Zdrave granice - zdrava igra” koje se bave temom prevencije bolesti zavisnosti od igara na sreću. Na sajtu AGOS <a href="https://agos.rs/" target="blank">www.agos.rs</a> postoje informacije i mogućnost pozivanja SOS telefonskog savetovališta 0800 301 302 ili slanja poruke stručnom timu radi konsultacija: <b>agospodrska@gmail.com</b>
                    <br/><br/>
                  </div>
                </div>
                <div className="pb-xl-0 m-auto">
                  <img src={img3} alt="" className="radius-4 my-4 my-md-5"></img>
                </div>
                <div className="about_tab bg-white radius-4 blue_text">
                  <div className="about_tab_header text-uppercase text-center">ISTRAŽIVANJE I RAZVOJ</div>
                  <div className="p-4 text-start">
                    NOVOMATIC je fokusiran na traženje odgovora na osnovna i tehnološki napredna pitanja.
                    <br/><br/>
                    Misija Grupe je osvajanje pozicije trendsetera i razvijanje najsavremenije inovativne opreme za igre na sreću.
                    <br/><br/>
                    Komparativna prednost u odnosu na konkurenciju je dvostruka uloga kompanije kao proizvođača i operatera u ovoj oblasti. Uz to, stalno proširujemo međunarodnu, usko povezanu mrežu istraživanja i razvoja najnovijih tehnologija. Fokusiramo se na srž samog procesa, ali i uočavanje i rešavanje izazova koje postavljaju tehnološki trendovi.
                    <br/><br/>
                  </div>
                </div>
                <div className="pb-xl-0 m-auto">
                  <img src={img7} alt="" className="radius-4 my-4 my-md-5"></img>
                </div>
              </div>
              <div className="col-12 col-md-6 ps-md-4">
                <div className="pb-xl-0 m-auto m-auto d-none d-md-block">
                  <img src={img1} alt="" className="radius-4 my-4 my-md-5"></img>
                </div>
                <div className="about_tab bg-white radius-4 blue_text">
                  <div className="about_tab_header text-uppercase text-center">Delatnost</div>
                  <div className="p-4 text-start">
                    Ekskluzivnost, kvalitet usluge i profesionalni menadžment učinio nas je liderima i na srpskom gaming tržištu. Odlikuju nas široka ponuda, najmoderniji automati i najsavremenije tehnologije iza kojih stoje NOVOMATIC standardi. 
                    U poslednjih nekoliko godina smo znatno proširili naš tim i svi smo posvećeni cilju što boljeg predstavljanja Novomatica u Srbiji. 
                    <br/><br/>
                    Konstantno usavršavanje, kao i rad na unapređenju organizacije, brzine i kvaliteta usluge koju pružamo, učinili su da uspešno sarađujemo sa svim priređivačima igara na sreću u Srbiji i držimo ubedljivo prvo mesto kada se radi o udelu NOVOMATIC automata na našem tržištu. 
                    <br/><br/>
                    Odlična saradnja sa svim klijenatima, njihovo zadovoljstvo našim uslugama u svim oblastima, bilo da je prodaja, rentiranje ili servisiranje automata u pitanju, potvrda je našeg kvaliteta kao i poverenja u Novo Investment. 
                  </div>
                </div>
                <div className="pb-xl-0 m-auto">
                  <img src={img5} alt="" className="radius-4 my-4 my-md-5"></img>
                </div>
                <div className="about_tab bg-white radius-4 blue_text">
                  <div className="about_tab_header text-uppercase text-center">OSNOVNA TRŽIŠTA</div>
                  <div className="p-4 text-start">
                    Poslovna politika NOVOMATIC-a je da nudi svoje proizvode i usluge u oblasti igara na sreću samo na uređenim tržištima sa jasnim regulatornim i zakonskim okvirima.
                    <br/><br/>
                    NOVOMATIC za cilj ima stabilan, kontrolisan i održiv rast. Doslednost na ovom polju delovanja pokazala se kao uspešna strategija u širenju kompanije. Osnovna tržišta na kojima se nalazimo su zemlje EU i države srednje, istočne i jugoistočne Evrope. Naša grupa je ranim pozicioniranjem uspostavila ključnu poziciju u ovim zemljama i vremenom proširila uticaj na tržištu. Osim daljeg rasta u pomenutim regionima, fokus je na prostorima koji obećavaju dugoročne i stabilne izglede za rast, poput Latinske Amerike i Azije, kao i u SAD gde je Novomatic aktivan već nekoliko godina.
                  </div>
                </div>
                <div className="pb-xl-0 m-auto">
                  <img src={img6} alt="" className="radius-4 my-4 my-md-5"></img>
                </div>
                <div className="about_tab bg-white radius-4 blue_text">
                  <div className="about_tab_header text-uppercase text-center">ODGOVORNO KOCKANJE</div>
                  <div className="p-4 text-start">
                    NOVOMATIC je uspostavio jedan od najmodernijih sistema u Evropi za prevenciju zavisnosti od kockanja. Odgovorno kockanje je jedan od prioriteta u poslovanju. Ponosni smo na koncept za sprečavanje zavisnosti implementiran u svim zemljama Evrope u kojima poslujemo. Pomenuti mehanizam obezbeđuje najviši nivo zaštite igrača, pre svega mlađe populacije.
                  </div>
                </div>
              </div>
            </div>
            <div className="about-line">
              <div className="line"></div>
              <div className="bullet-point"></div>
              <div className="bullet-point"></div>
              <div className="bullet-point"></div>
              <div className="bullet-point"></div>
              <div className="bullet-point"></div>
              <div className="bullet-point"></div>
            </div>
          </div>
        </WhiteGrayBackground>
    </>
  )
}

export default AboutNovomatic