import { useRef } from "react";
import ProductItem from "../../components/Products/ProductItem";

const CabinetRowContainer = ({ data }) => {
  const cabinetsRef = useRef();

  const cabinetsAnimation = () => {
    let counter = 0;
    if (!cabinetsRef.current) return;
    const cabinetsToAnimate = cabinetsRef.current.querySelectorAll('.cabinet-row-container .product_item_container');
    const reversedCabinets = [];
    for (let i = cabinetsToAnimate.length - 1; i >= 0; i--) {
      reversedCabinets.push(cabinetsToAnimate[i]);
    }
    if(reversedCabinets[counter].classList.contains('fade-in')) return;
    let interval = setInterval(() => {
      const singleCabinetToAnimate = reversedCabinets[counter];
      singleCabinetToAnimate.classList.add('fade-in')
      counter++
      if (counter === cabinetsToAnimate.length) {
        clearInterval(interval)
      }
    }, 200)
  }

  return (
    <div className="cabinet-row-container row g-0 justify-content-center" ref={cabinetsRef}>
      {data.map((item, key) => {
        return (
          <div className={`row col-12 col-md-3`} key={key}>
            <ProductItem data={item} route="/automati-detalji/" cabinetsAnimation={cabinetsAnimation} type="cabinets"/>
          </div>
        );
      })}
    </div>
  )
}
export default CabinetRowContainer