import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Navbar/Sidebar";
import Scrolltop from "../components/Scrolltop";
import CabinetsContainer from "../containers/Cabinets/CabinetsContainer";

const Cabinets = () => {
  
  const [isSidebarOpened, setIsSidebarOpened] = useState(null);

  return (
    <>
      <Header setIsSidebarOpened={setIsSidebarOpened}/>
      <div className="cabinets_page">
        <div className="automats">
          <CabinetsContainer />
          <Sidebar 
            isSidebarOpened={isSidebarOpened}
            setIsSidebarOpened={setIsSidebarOpened}
          />
          <Footer />
          <Scrolltop />
        </div>
      </div>
    </>
  );
};

export default Cabinets;
