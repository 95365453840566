import React from "react";
import Select from 'react-select'

const FilterControls = ({ handleChange, handleSelect, options }) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#eaeff5",
      height: "40px",
      border: state.isFocused ? 0 : 0,
      textAlign: "left",
      "&:hover": {
        cursor: "pointer"
      }
    }),
    dropdownIndicator: (base) => ({
      ...base,
      "svg": {
        fill: "#595959",
        "&:hover": {
          fill: "#4BAEE2"
        }
      }
    }),
    indicatorSeparator: () => null,
    singleValue: base => ({
      ...base,
      color: '#595959'
    })
  };

  return (
    <>
      {options && (
        <div className="row p-0 m-0">
          <div className="col-lg-6 col-md-6 col-sm-6 select mb-2">
            <Select
              styles={customStyles}
              className="react-select-container"
              options={options}
              isOptionDisabled={(option) => option.disabled}
              onChange={handleSelect}
              defaultValue={{ label: options[0].label, value: options[0].value }}
            ></Select>
          </div>
          <div className="d-flex filter_box col-lg-6 col-md-6 col-sm-6">
            <i className="fa fa-search"></i>
            <input placeholder="Pretražite..." className="w-100" aria-label="Search" onChange={handleChange}></input>
          </div>
        </div>
      )}
    </>
  )
}

export default FilterControls;