import { forwardRef, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import menuItems from "../../data/NavbarData.json"

const Submenu = forwardRef(({ openedTab }, ref) => {
  const [data, setData] = useState({});
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    if (!openedTab) return;
    setData(menuItems[openedTab-1])
  }, [openedTab]);

  
  const handleClick = (event, item) => {
    if (item.href) {
      if(item.href.startsWith('http')){
        window.open(item.href, '_blank');
      }
      else{
        navigate(item.href)
      }
    }
  }

  return(
    <>
        <div ref={ref} className={`submenu d-flex justify-content-center mx-auto ${openedTab ? 'active' : ''}`}>
          <div className="submenu-content row justify-content-between">
            <div className={`col-4 col-md-3 submenu_section1 ${location.pathname === "/" ? "text-white" : ""}`}>
              <h3>{((data || {}).description || {}).title}</h3>
              <p>{((data || {}).description || {}).text}</p>
            </div>
            <div className="row col-8 col-md-9 submenu_section2 pb-5 pt-4 ps-5gx-5">
              {((data || {}).links || []).map((item, key) => {
                return(
                  <div className={`${item.class ? item.class : 'col-12 col-lg-4' } text-start`} key={key}>
                    <div className={`submenu-link p-0 ${location.pathname === "/" ? "text-white" : ""}`} onClick={(event) => handleClick(event, item)}>
                      <div className="d-flex justify-content-between align-items-center submenu_item pb-3">
                        <div>{item.title}</div>
                        <i className={`fa fa fa-chevron-right ps-1 ${location.pathname === "/" ? "text-white" : ""}`}></i>
                      </div>
                    </div>
                    {item.links && (
                      <div className="text-start">
                        {item.links.map((i, key) => {
                          return(
                            <div className={`p-0 ${location.pathname === "/" ? "text-white" : ""} text-white submenu_game_links`} role="button" key={key} onClick={(event) => handleClick(event, i)}>
                              <div className="d-flex justify-content-between align-items-center submenu_subitem">
                                  <div>{i.title}</div>
                                  <i className={`fa fa-thin fa-chevron-right ${location.pathname === "/" ? "text-white" : ""}`}></i>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
    </>
  )
});

export default Submenu